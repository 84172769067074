<template>
  <ConfirmDialog
    persistent
    maxWidth="700px"
    :value.sync="show"
    title="Filtry"
    showCloseBtn
  >
    <v-form slot="content" class="mx-2">
      <v-layout column>
        <v-layout align-center wrap>
          <DefaultLabel>Pokazuj:</DefaultLabel>
          <DefaultButton
            :primary="last_appointment_date === 'week'"
            small
            @click.prevent="last_appointment_date = 'week'"
            >Ostatnie 7 dni</DefaultButton
          >
          <DefaultButton
            :primary="last_appointment_date === 'month'"
            small
            @click.prevent="last_appointment_date = 'month'"
            >Ostatnie 30 dni</DefaultButton
          >
          <DefaultButton
            :primary="last_appointment_date === 'year'"
            small
            @click.prevent="last_appointment_date = 'year'"
            >Ostatnie 12 miesięcy</DefaultButton
          >
          <DefaultButton
            :primary="last_appointment_date === ''"
            small
            @click.prevent="last_appointment_date = ''"
            >Od początku</DefaultButton
          >
        </v-layout>
        <v-layout column>
          <v-switch
            color="expressive_green"
            class="zg-text-control"
            inset
            label="Niezakończona wizyta"
            hide-details
            v-model="unfinished"
            :ripple="false"
          ></v-switch>
          <v-switch
            color="expressive_green"
            class="zg-text-control"
            inset
            label="Brak zgody na zabieg"
            hide-details
            v-model="consent"
            :ripple="false"
          ></v-switch>
          <v-switch
            color="expressive_green"
            class="zg-text-control"
            inset
            label="Brak adresu"
            hide-details
            v-model="address"
            :ripple="false"
          ></v-switch>
          <v-switch
            color="expressive_green"
            class="zg-text-control"
            inset
            label="Brak numeru identyfikującego pacjenta"
            hide-details
            v-model="identity"
            :ripple="false"
          ></v-switch>
        </v-layout>
      </v-layout>
    </v-form>
    <v-layout slot="footer">
      <DefaultButton @click.prevent="reset">Resetuj</DefaultButton>
      <v-spacer></v-spacer>
      <v-layout shrink>
        <DefaultButton @click.prevent="hide">Anuluj</DefaultButton>
        <DefaultButton primary @click.prevent="save">Zapisz</DefaultButton>
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
export default {
  props: {
    value: {},
    pagination: {},
  },
  data() {
    return {
      unfinished: false,
      consent: false,
      address: false,
      identity: false,
      last_appointment_date: "",
    };
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    hide() {
      this.show = false;
    },
    reset() {
      this.unfinished = false;
      this.consent = false;
      this.address = false;
      this.identity = false;
      this.last_appointment_date = "";
    },
    save() {
      this.$set(
        this.pagination,
        "unfinished",
        this.unfinished ? this.unfinished : undefined
      );
      this.$set(this.pagination, "consent", this.consent ? false : undefined);
      this.$set(this.pagination, "address", this.address ? false : undefined);
      this.$set(this.pagination, "identity", this.identity ? false : undefined);
      this.$set(
        this.pagination,
        "last_appointment_date",
        this.last_appointment_date === ""
          ? undefined
          : this.last_appointment_date
      );

      this.$emit("update:pagination", this.pagination);
      this.hide();
    },
  },
  mounted() {
    this.unfinished = !!this.pagination.unfinished;
    this.consent = this.pagination.consent !== undefined;
    this.address = this.pagination.address !== undefined;
    this.identity = this.pagination.identity !== undefined;
    this.last_appointment_date = this.pagination.last_appointment_date
      ? this.pagination.last_appointment_date
      : "";
  },
};
</script>